<template>
  <PrimaryMenu />

  <Header title="Blog / Hírek" />

  <SectionBlog titleDisabled sectionActionButtonsDisabled />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlog from "@/components/layout/sections/SectionBlog";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";

export default {
  name: "BlogPosts",
  title: "Blog",
  components: {
    PrimaryMenu,
    Header,
    SectionBlog,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
